<template>
  <div class="relative noto mt-10">
    <h1
      class="f-20 f-14-xxs f-13-xxxs inline-block f-gray mt-10 mb-6 relative pt-10"
    >
      無料会員登録
    </h1>
    <div class="mb-16 f-11">
      <a href="/login"> 登録済の方はこちら</a>
    </div>
    <div>
      会員登録いただくと、<span class="f-red bold">非公開</span
      >物件を含む&nbsp;<br class="dis-sm"><span class="f-16 bold f-red">{{ count }}</span
      ><span class="f-red pl-1 bold">件</span>&nbsp;が閲覧できます
      <div class="mt-4">さらに&nbsp;ご契約時、仲介手数料<br class="dis-xs">&nbsp;<span class="f-16 bold f-red">10</span
      ><span class="f-red pl-1 bold">％</span>&nbsp;引いたします！</div>
    </div>

    <v-row class="w600 relative-center mt-10 mb-5 text-center">
      <v-col cols="12" class="noto7 f-12 f-10-xxxs"> 会員登録の流れ </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <v-card
          class="f-11 f-10-xxs card_flow px-4 py-10 relative overflow-hidden w300  relative-center"
        >
          <div
            class="flow_num absolute t--5 l--5 bold rounded f-16 flex-center"
          >
            <div>1</div>
          </div>
          <i class="far fa-edit flow_icon"></i>
          <div class="mt-5">会員登録フォームに必要事項をご記入し送信します</div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <v-card
          class="f-11 f-10-xxs card_flow px-4 py-10 relative overflow-hidden  w300 relative-center"
        >
          <div
            class="flow_num absolute t--5 l--5 bold rounded f-16 flex-center"
          >
            <div>2</div>
          </div>
          <i class="fas fa-home flow_icon"></i>
          <div class="mt-5">送信後、すぐに非公開物件が閲覧可能になります</div>
        </v-card>
      </v-col>
    </v-row>
    <div>
      <Order />
    </div>
  </div>
</template>

<script>
import Order from "@/components/parts/order.vue";
import axios from "axios";
export default {
  data() {
    return {
      count: 0,
    };
  },

  components: {
    Order,
  },

  async mounted() {
    if (window.innerWidth > 1449) {
      this.color = "rgba(255,255,255,0.8)";
    }
    const response = await axios.post("/estate/register_estate_count");
    this.count = response.data.count.toLocaleString();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/base.scss";
h1 {
  padding: 10px 20px;
  border-bottom: 3px solid $orange;
}

.card_flow {
  border: 2px solid #009688;
}

.flow_icon {
  color: #009688;
  font-size: 3rem;
}

.flow_num {
  background: #009688;
  color: white;
  width: 50px;
  height: 50px;
}
</style>
